import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menuBar: boolean;
  constructor() {
    this.menuBar = false;
  }

  ngOnInit() {
  }

  showMenu() {
    this.menuBar = !this.menuBar;
    if (this.menuBar)
      document.getElementById('myNavbar').style.display = "block";
    else
      document.getElementById('myNavbar').style.display = "none";
  }

  showComponent(name) {
    this.menuBar = false;
    document.getElementById('myNavbar').style.display = "none";
    if (name == 'workshop') {
      document.getElementById('work-shop').style.display = "block";
      document.getElementById('faculty').style.display = "block";

      document.getElementById('home').style.display = "none";
      document.getElementById('who').style.display = "none";
      document.getElementById('what').style.display = "none";
      document.getElementById('where').style.display = "none";
      document.getElementById('courses').style.display = "none";
      document.getElementById('contact').style.display = "none";
      document.getElementById('footer').style.display = "none";
      document.getElementById('Course-details').style.display = "none";
    } else if (name == 'courses') {
      document.getElementById('courses').style.display = "block";

      document.getElementById('work-shop').style.display = "none";
      document.getElementById('faculty').style.display = "none";
      document.getElementById('home').style.display = "none";
      document.getElementById('who').style.display = "none";
      document.getElementById('what').style.display = "none";
      document.getElementById('where').style.display = "none";
      document.getElementById('contact').style.display = "none";
      document.getElementById('footer').style.display = "none";
    } else {
      document.getElementById('Course-details').style.display = "none";
      document.getElementById('work-shop').style.display = "none";
      document.getElementById('faculty').style.display = "none"
      document.getElementById('home').style.display = "block";
      document.getElementById('who').style.display = "block";
      document.getElementById('what').style.display = "block";
      document.getElementById('where').style.display = "block";
      document.getElementById('courses').style.display = "block";
      // document.getElementById('contact').style.display = "block";
      document.getElementById('footer').style.display = "block";
    }
  }

}
