import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  trainingLanguages: any = [
    {
      img: '/assets/img/courselogo/java-logo.jpg',
      name: 'Java',
      content: 'Java is a general-purpose programming language and a popular object-oriented programming language.It is one of the world’s leading development languages.' +
        'It is an underlying technology required by many desktop, mobile applications and websites.',
      href: 'java'
    },
    {
      img: '/assets/img/courselogo/Python-logo.jpg',
      name: 'Python',
      content: 'Python (Open source software) is an interpreted, high-level, general-purpose programming language.' +
        'Anything can done using python. interacting with hardware to human interface. Our imagination is limit of it useage.',
      href: 'python'
    },
    {
      img: '/assets/img/courselogo/ui-logo.jpg',
      name: 'UI Techonologies',
      content: ' User Interface (UI) is to provide the user interface solution to the end user, including the final look, feel and behaviour of the interface in different contexts.',
      href: 'ui-technologies'
    },
    {
      img: '/assets/img/courselogo/database-logo.jpg',
      name: 'Postgresql',
      content: 'Data is core heart of any system. Today data is gold.PostgreSQL is a free and open-source relational database management system emphasizing extensibility and technical standards compliance. ' +
        'It is designed to handle a range of workloads, from single machines to data warehouses or Web services with many concurrent users.',
      href: 'postgresql'
    },
    {
      img: '/assets/img/courselogo/OS_Mobile_logo.jpg',
      name: 'Operating System and Dev Apps',
      content: 'We use operating system which is a free distribution of usage of common cause.' +
        'DevOps is a set of practices that automates the processes between software development and IT teams, in order that they can build, test, and release software faster and more reliably.',
      href: 'operating-system-and-dev-apps'
    },
    {
      img: '/assets/img/courselogo/agile_logo.jpg',
      name: 'Agile',
      content: 'Agile software development is an approach to software development under which requirements and solutions evolve through ' +
        'the collaborative effort of self-organizing and cross-functional teams and their customer/end user',
      href: 'agile'
    }
  ]

  constructor(private router: Router) {

  }

  ngOnInit() {

  }

  navigatePage(href) {
    document.getElementById('Course-details').style.display = "block";
    for (let i = 0; i < this.trainingLanguages.length; i++) {
      if (href == this.trainingLanguages[i].href) {
        if (href == 'java')
          document.getElementById(this.trainingLanguages[i].href).style.display = "block";
        else
          document.getElementById(this.trainingLanguages[i].href).style.display = "none";
      }

      else
        document.getElementById(this.trainingLanguages[i].href).style.display = "none";
    }
  }

}
