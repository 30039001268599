import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faculity-member',
  templateUrl: './faculity-member.component.html',
  styleUrls: ['./faculity-member.component.scss']
})
export class FaculityMemberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
