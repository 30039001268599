import { Component, OnInit } from '@angular/core';
declare var google;

@Component({
  selector: 'app-where',
  templateUrl: './where.component.html',
  styleUrls: ['./where.component.scss']
})
export class WhereComponent implements OnInit {

  images: any = [];
  slideIndex: any;
  constructor() {
    this.images = ["assets/img/office1.jpg",
      "assets/img/office2.jpg",
      "assets/img/office3.jpg"]
  }

  ngOnInit() {
    var mapProp = {
      center: new google.maps.LatLng(12.933773, 80.2305223),
      zoom: 18,
    };
    var map = new google.maps.Map(document.getElementById("googleMap"), mapProp);

    this.slideIndex = 0;
    this.showSlides();
  }



  showSlides() {
    var i;
    var slides: any = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    this.slideIndex++;
    if (this.slideIndex > slides.length) { this.slideIndex = 1 }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    dots[this.slideIndex - 1].className += " active";
    setTimeout(() => {
      this.showSlides();
    }, 3000)
  }

  plusSlides(n) {
    this.changeSlides(this.slideIndex += n);
  }

  currentSlide(n) {
    this.changeSlides(this.slideIndex = n);
  }

  changeSlides(n) {
    var i;
    var slides: any = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    dots[this.slideIndex - 1].className += " active";
  }

}
