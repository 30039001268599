import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoursesComponent } from './courses/courses.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { WhoComponent } from './who/who.component';
import { WhatComponent } from './what/what.component';
import { WhereComponent } from './where/where.component';
import { WorkShopComponent } from './work-shop/work-shop.component';
import { FaculityMemberComponent } from './faculity-member/faculity-member.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { JavaComponent } from './java/java.component';
import { PythonComponent } from './python/python.component';
import { UiTechnologiesComponent } from './ui-technologies/ui-technologies.component';
import { PostgresqlComponent } from './postgresql/postgresql.component';
import { OperatingSystemAndDevAppsComponent } from './operating-system-and-dev-apps/operating-system-and-dev-apps.component';
import { AgileComponent } from './agile/agile.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { MainComponent } from './main/main.component';

@NgModule({
  declarations: [
    AppComponent,
    CoursesComponent,
    MenuComponent,
    HomeComponent,
    WhoComponent,
    WhatComponent,
    WhereComponent,
    WorkShopComponent,
    FaculityMemberComponent,
    ContactComponent,
    FooterComponent,
    JavaComponent,
    PythonComponent,
    UiTechnologiesComponent,
    PostgresqlComponent,
    OperatingSystemAndDevAppsComponent,
    AgileComponent,
    InfrastructureComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
