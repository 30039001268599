import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operating-system-and-dev-apps',
  templateUrl: './operating-system-and-dev-apps.component.html',
  styleUrls: ['./operating-system-and-dev-apps.component.scss']
})
export class OperatingSystemAndDevAppsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
